$border-radius-lg: 25;
$padding-base-horizontal: 20;
$black: 'black';

.login-page {
  .card-login {
    border-radius: $border-radius-lg;
    padding-bottom: $padding-base-horizontal;

    .card-header {
      padding: 0 0 100px;
      overflow: hidden;

      img {
        position: absolute;
      }

      .card-title {
        position: relative;
        font-size: 5em;
        font-weight: 900;
        color: $black;
        text-transform: lowercase;
        margin-left: -5px;
        z-index: 1;
      }
    }

    .btn-wd {
      min-width: 180px;
    }

    .logo-container {
      width: 65px;
      margin: 0 auto;
      margin-bottom: 55px;

      img {
        width: 100%;
      }
    }

    .input-group:last-child {
      margin-bottom: 40px;
    }

    .input-group-focus {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text,
      .form-control {
        background-color: #ffffff;
        border-color: #853d89;
      }

  }
}

.link {
  font-size: 10px;
  text-decoration: none;
}

.card-black {
  .link {
    color: #ffffff;
  }
}

}

.full-page {
  & > .content,
  & > .footer {
    position: relative;
    z-index: 4;
  }

  & > .content {
    padding-bottom: 150px;
    padding-top: 150px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-left: 48px;
    padding-right: 48px;

    .container {
      color: #ffffff;
    }
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  &.pricing-page {
    .description {
      margin-bottom: 65px;
      color: rgba(255,255,255, 0.7);
    }
  }

  &.register-page {
    .info-horizontal {
      padding: 0px 0px 20px;
    }

    .info-horizontal {
      text-align: left !important;

      .icon {
        margin-top: 0;

        > i {
          font-size: 2em;
        }

        &.icon-circle {
          width: 65px;
          height: 65px;
          max-width: 65px;
          margin-top: 8px;

          i {
            display: table;
            margin: 0 auto;
            line-height: 3.5;
            font-size: 1.9em;
          }
        }
      }

      .description {
        overflow: hidden;

        .info-title {
          margin-bottom: 20px;
        }
      }
    }
  }
}
.login-page .card-login.card-white .input-group-prepend .input-group-text {
  border-color: rgba(29,37,59,.2);
  color: #1d253b;
}
.login-page .card-login.card-white .input-group-prepend .input-group-text {
   border-right: none;
 }